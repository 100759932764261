//import { DataService } from './../../shared/async-services/data.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js'; 
import { ListTable } from '@shared/models';
import { QueryParam } from '@app/models/user-model';
import { DataService } from '@shared/async-services/data.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  encryptSecretKey = 'letalliance_1234';
  AesKey = "HR$2pIjHR$2pIj12";
  AesIV = "HR$2pIjHR$2pIj12";
  public addOnCRMBasePath = "CRM/";
  public addOnOperationBasePath = "operation/";
  public documentBasePath = 'Document/';
  public activityBasePath = 'Activity/';
  

  constructor(
    protected httpClient: HttpClient,
    protected dataService: DataService 
  ) { }
  public encryptedParam: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  
  public AddUpdateUserAccess(data: any,userId:any): Observable<any> {      
      return this.dataService.post(this.addOnOperationBasePath+"UsersAccess/"+ userId, data);          
  }
  public AddUpdateAgentAccess(data: any,agentId:any): Observable<any> {      
    return this.dataService.post(this.addOnOperationBasePath+"UsersAccess/SaveAgentAccess/"+ agentId, data);          
  }
  public AddDefaultAgentRoles(agentId: any): Observable<any> {      
    return this.dataService.get(this.addOnOperationBasePath+"Users/AddDefaultAgentRoles" ,agentId);          
  }
  public SaveAccessForAllUsers(agentId: any): Observable<any> {      
    return this.dataService.get(this.addOnOperationBasePath+"Users/SaveAccessForAllUsers/" + agentId);          
  }
  public GetUserScreenAccess(userId: any): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath + "UsersAccess/GetUserAccessByUserId", userId);
  }
  public GetAgentScreenAccess(agentId: any): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath + "UsersAccess/GetAgentAccessByAgentId", agentId);
  }

  public GetUserList(data: any): Observable<any> {
    return this.dataService.post(this.addOnOperationBasePath + "Users/GetUserList", data);
  }
  public GetUserById(userId:any): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath + "Users/GetUserById",userId);
  }
 
  public AddUpdateUser(data: any): Observable<any> {  
    if(data.userId!=null && data.userId!=0 && data.userId!="" && data.userId!=undefined )
    {
      return this.dataService.put(this.addOnOperationBasePath+"Users/" + data.userId, data);  
    }  
    else{
    return this.dataService.post(this.addOnOperationBasePath + "Users/CreateUser", data);
    }
  }
  public AddUser(data: any): Observable<any> {    
    return this.dataService.post(this.addOnOperationBasePath + "Users/CreateUser", data);
  }
  public UpdateUser(data: any): Observable<any> {
    return this.dataService.put(this.addOnOperationBasePath+"Users/" + data.UserId, data);
  }
  public UpdateUserProfileDetails(data: any): Observable<any> {
    return this.dataService.put(this.addOnOperationBasePath+"Users/UpdateUserProfileDetails", data);
  }
  public UpdateCognitoUserPassword(data: any): Observable<any> {
    return this.dataService.put(this.addOnOperationBasePath + "Login/UpdateCognitoUserPassword", data);
  }
  public ResetPassword(data: any): Observable<any> {
    return this.dataService.put(this.addOnOperationBasePath + "Login/ResetPassword", data);
  }
  
  public DeleteUser(userId: any): Observable<any> {
    return this.dataService.delete(this.addOnOperationBasePath+"Users/" +userId);
  }
  public GetUserBySecurityCode(securityCode: any): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath + "Login/GetUserBySecurityCode/"+ securityCode);
  }
  public getRoles(roleId:any): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath+"roles",roleId);
  }
  public getSystemList(): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath+"systems");
  }

  public GetUserProfileInfo(): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath+"Users/GetUserProfileInfo");
  }

  public HasStopLogin():Observable<any>{
    return this.dataService.get(this.addOnOperationBasePath+"Users/HasStopLogin")
  }

  
  public SendPasswordResetLink(userData:any) {
    return this.dataService.post(this.addOnOperationBasePath + `Users/SendResetPasswordLink`, userData);
  }
  public GetActivityAgentTabWise(userId: number, module: string, submodule: string): Observable<any> {
    if (submodule != null && submodule != "")
      return this.dataService.get(this.activityBasePath+`Activity/GetAllActivityByKeySubModule/${userId}/${module}/${submodule}`);
    else
      return this.dataService.get(this.activityBasePath+`Activity/GetAllActivityByKeyNModule/${userId}/${module}`);
  }

  public GetActivitybySubModuleNModule( module: string, submodule: string): Observable<any> {
      return this.dataService.get(this.activityBasePath+`Activity/GetActivitybySubModuleNModule/${module}/${submodule}`);
  }
  
  public GetActivitybySubModuleNKeyPaged(objReq: any): Observable<any> {
    return this.dataService.post(this.activityBasePath+
      "Activity/GetActivitybySubModuleNKeyPaged", objReq);
  }

  public GetAllActivityByModule( module: string): Observable<any> {
    return this.dataService.get(this.activityBasePath+`Activity/GetAllActivityByModule/${module}`);
  }
  

  // public GetUsers(firstName: string, lastName: string): Observable<string[]> {
  //   const params = new HttpParams()
  //     .set('firstName', firstName)
  //     .set('lastName', lastName);

  //   return this.dataService.get(this.addOnOperationBasePath + "Users/GetUsers");
  // }

  public saveUserSystemDetails(userSystem:any):Observable<any> {
    
    return this.dataService.post<any>(this.addOnOperationBasePath+ "Users/SaveUserSystemDetails", userSystem);
  }
  public uploadUserDocument(docdata:any):Observable<any>{
    return this.dataService.postDocument(this.documentBasePath+ "Document/UploadDocument", docdata);
  }

  public updateUserDocumentPath(userData:any):Observable<any>{  
    return this.dataService.put<any>(this.addOnOperationBasePath+ "Users/UpdateUserDocumentPath", userData);
  }
 
  public GetActurisNotesDocument(agentId: any, acturisRefno: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDocumentStore/GetActurisNotesDocument/' + agentId + '/' + acturisRefno);
  }

  public noWhitespaceValidator(control: FormControl) {
    const validVal =
      (control.value || "").trim().length == (control.value || "").length;
    if (validVal) {
      return null;
    }
    return { whitespace: true };
  }
  public  noBetweenWhitespaceValidator(control: FormControl) {
    const isSpace = (control.value || '').match(/\s/g);    
    return isSpace ? {whitespaceBetwwen: true} : null;
}
  changePassword(changePasswordData: any): Observable<any> {
    return this.dataService.put<any>(this.addOnOperationBasePath+ "Login/ChangePassword", changePasswordData);
  }

  updateQueryParam(data): void {
    this.encryptedParam.next(data);
  }

  public encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }
  public GetActiveAgentList(): Observable<Array<any>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetActiveAgentList');
  }
  public getQueryParam(data): QueryParam {
    const decryptparams: QueryParam = this.decryptData(data);
    return decryptparams;
  }
  public decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (!control.valid) {
        console.log(field + ' Is Invalid');
      }
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public decryptAndParseQueryString(enceyptedData: string) {
    if (enceyptedData === undefined || enceyptedData === null) {
      return;
    }
    const decryptparams = this.decryptData(enceyptedData);
    let userId;
    if (decryptparams.userId !== undefined && decryptparams.userId !== null) {
      userId = parseInt(decryptparams.userId);
    }
    return {
      userId: userId,
    };
  }

  getCountries(): Observable<any> {
    return this.dataService
      .get(this.addOnCRMBasePath + 'countries');
  }

  
}
export function checkFileType(files: any) {
  for (let index = 0; index < files.length; index++) {
    if (
      files[index].name.toLowerCase().split(".").pop() === "jpeg" ||
      files[index].name.toLowerCase().split(".").pop() === "jpg" ||
      files[index].name.toLowerCase().split(".").pop() === "png" ||
      files[index].name.toLowerCase().split(".").pop() === "gif" ||
      files[index].name.toLowerCase().split(".").pop() === "bmp" ||
      files[index].name.toLowerCase().split(".").pop() === "pdf" ||
      files[index].name.toLowerCase().split(".").pop() === "msg" ||
      files[index].name.toLowerCase().split(".").pop() === "doc" ||
      files[index].name.toLowerCase().split(".").pop() === "docx" ||
      files[index].name.toLowerCase().split(".").pop() === "xls" ||
      files[index].name.toLowerCase().split(".").pop() === "xlsx" ||
      files[index].name.toLowerCase().split(".").pop() === "pptx" ||
      files[index].name.toLowerCase().split(".").pop() === "ppt" ||
      files[index].name.toLowerCase().split(".").pop() === "pptm" ||
      files[index].name.toLowerCase().split(".").pop() === "potx" ||
      files[index].name.toLowerCase().split(".").pop() === "ppsx"
    ) {
      return true;
    }
  }
  return false;
}

export function checkFileTypeInvoice(files: any) {
  var filTypeExt = "xls,xlsx,csv";
  var filetype = filTypeExt.split(',');
  
  for (let index = 0; index < files.length; index++) {
    if (
      filetype.indexOf(files[index].name.toLowerCase().split('.').pop()) > -1

    ) {
      return true;
    }
  }
  return false;
}


export function checkFileSize(files: any, fileSize: any = 20000000) {
  let isFileSizeAllowed = false;
  for (let index = 0; index < files.length; index++) {
    if (files[index].size < fileSize) {
      isFileSizeAllowed = true;
      return isFileSizeAllowed;
    }
  }
  return isFileSizeAllowed;
}


export function getBoolean(value) {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
    case 'True':
    case 'Yes':
    case 'On':
      return true;
    case undefined:
      return false;
    case null:
      return false;
    case 'null':
      return false;
    case 'undefined':
      return false;
    case '':
      return false;
    default:
      return false;
  }
}

export function getNumber(value) {
  switch (value) {
    case 1:
    case '1':
      return 1;
    case 2:
    case '2':
      return 2;
    default:
      return 0;
  }
}
